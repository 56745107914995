<script setup lang="ts">
const route = useRoute()
const prefix = computed(() => route.path.includes('seller') ? 'seller' : 'customer')
const userStore = useUserStore()

const localePath = useLocalePath()

const logout = async () => {
  await userStore.logout()
  await navigateTo(localePath('/auth/login/'))
}

const userName = computed(() => userStore.user.name ? userStore.user.name : userStore.user.email)
</script>

<template>
  <aside
    id="sidenav-open"
    class="sidenav"
  >
    <div class="sidenav__content">
      <ClientOnly>
        <CommonLogo class="sidenav__brand" />
        <div class="sidenav__user">
          <CommonAvatar
            size="32"
            :name="userName"
            background="var(--c-orange-1)"
            color="var(--c-orange-2)"
          />
          <span>{{ userName || $t(`${prefix}.nav.user`) }}</span>
        </div>
      </ClientOnly>
      <AccountNavSeller
        v-if="route.path.includes('seller')"
        @logout="logout"
      >
      </AccountNavSeller>
      <AccountNavCustomer
        v-else
        @logout="logout"
      >
      </AccountNavCustomer>
    </div>
    <div class="sidenav__bottom">
      <CommonDiscussProject />
    </div>
  </aside>
</template>

<style lang='scss' scoped>
.sidenav {
  display: grid;
  grid-template-rows: 1fr auto;
  background: var(--c-bg-special);
  padding: 27px 0 44px;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 28px
  }

  &__brand,
  &__user,
  &__bottom {
    padding-inline: 16px;
  }

  &__brand {
    margin-block-end: 22px;
  }

  &__user {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__bottom {
    --fd: column;
    --ai: left;
  }
}
</style>