<script setup lang="ts">
const emit = defineEmits(['logout'])

const accountSellerMenu =ref([
  {
    key: '0',
    label: 'crm',
    icon: 'IconCrm',
    // headericon: '/svg/account-menu/arrow.svg',
    items: [
      { to: `/account-seller/stat`, label: 'stat', icon: 'IconStat' },
      { to: `/account-seller/chat`, label: 'chat', icon: 'IconChat' },
      { to: `/account-seller/requests`, label: 'requests', icon: 'IconRequests' },
    ]
  },
  {
    key: '1',
    label: 'profile',
    icon: 'IconProfile',
    // headericon: '/svg/account-menu/arrow.svg',
    items: [
      { to: `/account-seller/safety`, label: 'safety', icon: 'IconSafety' },
      { to: `/account-seller/organization`, label: 'organization', icon: 'IconOrganization' },
      { label: 'logout', icon: 'IconLogout', command: () => emit('logout') },
    ]
  },
])

const expandedKeys = ref({'0': true, '1': true})
</script>

<template>
  <nav class="sidenav__nav">
    <PrimePanelMenu
      multiple
      unstyled
      :model="accountSellerMenu"
      :expandedKeys="expandedKeys"
      :pt="{
        root: { class: 'panel-menu' },
        panel: { class: 'panel' },
        header: { class: 'header' },
        menu: { class: 'menu' },
        menuitem: { class: 'menu-item', ref: 'logout' },
        content: { class: 'content' },
      }"
    >
      <template #item="{ item }">
        <ClientOnly>
          <NuxtLinkLocale
            :to="item.to"
            class="panel-menu__item"
          >
            <CommonIcon
              :iconName="item.icon ? item.icon : ''"
              width="12"
              height="13"
              class="panel-menu__icon"
            />
            <span class="panel-menu__title">{{ $t(`seller.nav.${item.label}`)
            }}</span>
            <img
              :src="item.headericon"
              alt=""
              class="panel-menu__arrow"
            >
          </NuxtLinkLocale>
        </ClientOnly>
      </template>
    </PrimePanelMenu>
  </nav>
</template>

<style lang='scss' scoped>
.panel-menu {
  min-width: 225px;
  // width: fit-content;
  padding-inline: 16px;

  &__item {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--c-text-primary);
    transition: color .2s;
  }

  &__icon {
    margin-right: 8px;
  }

  &__arrow {
    margin-left: auto;
    transform: rotate(180deg);
    transition: transform .2s;
  }
}

:deep(.header) {
  font-weight: 600;
  padding-block: 20px 6px;
  padding-inline-end: 16px;
}

:deep(.header[aria-expanded=true] .panel-menu__arrow) {
  transform: rotate(0deg);
}

:deep(.menu-item) {
  list-style: none;
  list-style-position: inside;
  cursor: pointer;
}

:deep(.content>.panel-menu__item) {
  padding-inline-start: 20px;
  padding-block: 6px;
  margin-block-end: 8px;
  font-size: 14px;
  border-radius: 21px;
}

:deep(.content>.panel-menu__item:hover) {
  background-color: var(--c-brand);
  transition: background-color .2s;
}

:deep(.content>.panel-menu__item:hover .panel-menu__icon>path) {
  fill: var(--c-white);
}

:deep(.content>.panel-menu__item:hover .panel-menu__title),
:deep(.content>.panel-menu__item.router-link-active .panel-menu__title) {
  color: var(--c-white);
}

:deep(.content>.panel-menu__item.router-link-active) {
  background-color: var(--c-brand);
  transition: background-color .2s;
  color: var(--c-white);
}
</style>