<script setup lang="ts">
const emit = defineEmits(['logout'])
</script>

<template>
  <nav class="sidenav__nav">
    <NuxtLinkLocale
      v-for="{ to, item, icon, method } in accountCustomerMenu"
      :key="to"
      :to="!method ? `${to}` : ''"
      class="sidenav__link"
      @click="method ? emit('logout') : null"
    >
      <CommonIcon
        :iconName="icon"
        width="12"
        height="13"
        class="sidenav__icon"
      />
      {{ $t(`customer.nav.${item}`) }}
    </NuxtLinkLocale>
  </nav>
</template>

<style lang='scss' scoped>
.sidenav {
  &__nav {
    min-width: 225px;
    padding-inline: 16px;
  }

  &__link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--c-text-primary);
    font-weight: 600;
    border-radius: 21px;
    padding: 6px 16px;
    margin-block-end: 16px;
    transition: color .2s, background-color .2s;
  }

  &__link:last-child {
    cursor: pointer;
  }

  &__link:hover,
  &__link.router-link-active {
    color: var(--c-white);
    background-color: var(--c-brand);
  }

  &__icon {
    margin-right: 8px;
  }
}
</style>